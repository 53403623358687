export default {
  userIcon: {
    tooltipText: 'Kirjaudu sisään',
  },
  home: {
    welcomeText: 'Tervetuloa',
    thanksText: 'Kiitos jäsenyydestäsi!',
    nextText: 'Eteenpäin',
    prevText: 'Takaisin',
  },
  leftNavigationDrawer: {
    login: 'Kirjaudu sisään',
    register: 'Rekisteröidy',
    account: 'Tili',
    logout: 'Kirjautua ulos',
    wishes: 'Toivelista',
    myShowCase: 'Minun esittelyni',
    createProduct: 'Luo uusi mainoksi',
    messenger: 'Viestit',
    blog: 'Blog',
    category: 'Luokka',
    advertisements: 'Mainokset',
    users: 'Käyttäjät',
    roles: 'Roolit',
  },
  login: {
    emailLabel: 'Sähköposti',
    passwordLabel: 'Salasana',
    forgotPasswordText: 'Unohtuiko salasana?',
    rememberCheckbox: 'Muista minut',
    loginBtnText: 'Kirjaudu',
    registerAccountBreaf: 'Eikö sinulla ole tiliä?',
    registerAccountText: 'Rekisteröidy',
    socialLoginText: 'Tai',
    credentialError: 'Annetut tunnistetiedot ovat virheellisiä.',
    badContent: 'Sähköpostin tulee olla voimassa oleva sähköpostiosoite.',
    google: 'Jatka googlella',
  },
  register: {
    socialLoginText: 'Tai rekisteröidy',
    nameLabel: 'Nimi',
    passwordLabel: 'Salasana',
    passwordConfirmLabel: 'Vahvista salasana',
    registerBtnText: 'Rekisteröidy',
  },
  forgotPassword: {
    h1: 'Salasanan palautuslomake',
    text: 'Kirjoita sähköpostiosoitteesi ja siihen lähetetään linkki salasanan vaihtoon',
    successBtn: 'Lähettää sähköpostia',
  },
  resetPassword: {
    h1: 'Luo uusi salasana',
    text: 'Luo uusi salasana ja tallenna se.',
    successBtn: 'Tallenna salasana',
    passwordLabel: 'Salasana',
    passwordRepeatLabel: 'Toista salasana',
  },
  appSwitchButton: {
    tooltipText: 'Muuttaa näkemystä',
  },
  search: {
    label: 'Hae kirjoittamalla...',
  },
  notFound: {
    h1: 'Näyttää siltä, että olet löytänyt oven suureen ei mitään',
    subtext: 'Anteeksi tuosta! Vieraile kotisivuillamme saadaksesi minne sinun täytyy mennä.',
    btn: 'Siirry pääsivulle',
    emptySearchProductText: 'Valitettavasti emme löytäneet mitään. Yritä muuttaa pyyntöäsi.',
    emptyWishlistText: 'Toivelistasi on tällä hetkellä tyhjä.',
  },
  appNav: {
    wishTooltip: 'Sinun toiveesi',
    messengerTooltip: 'Sinun viestisi',
  },
  newProductForm: {
    h1: 'Luo uusi mainoksi',
    nameStep: 'Tuotteen nimi',
    nameLabel: 'Otsikko, kirjoita mitä myyt.',
    cityStep: 'Valitse kaupunki',
    citiesLabel: 'Valitse kaupunki',
    descriptionStep: 'Kuvaile tuotettasi ja lisää hinta.',
    descriptionLabel: 'Kuvaile myymääsi tuotetta.',
    fileStep: 'Lisää valokuva',
    fileLabel: 'Lataa kuva tuotteestasi',
    addressLabel: 'Kirjoita osoite tarvittaessa',
    priceLabel: 'Hinta',
    noData: 'Kaupunkia ei löydy',
    required: 'Kenttä on pakollinen',
    createError: 'Jotain meni pieleen',
    successfullyCreate: 'Loistava! Tuotteesi on luotu onnistuneesti!',
    selectCatalog: 'Valitse luettelo',
    selectCatalogDescription: 'Valitse luokka, jossa tuotteesi näytetään.',
    editCategoryBtn: 'Vaihda luokkaa',
    saveBtn: 'Tallentaa',
    nextBtn: 'Eteenpäin',
    prevBtn: 'Takaisin',
  },
  editProductForm: {
    h1: 'Luo uusi mainoksi',
    nameLabel: 'Otsikko, kirjoita mitä myyt.',
    citiesLabel: 'Valitse kaupunki',
    descriptionLabel: 'Kuvaile myymääsi tuotetta.',
    fileLabel: 'Lataa kuva tuotteestasi',
    addressLabel: 'Kirjoita osoite tarvittaessa',
    priceLabel: 'Hinta',
    noData: 'Kaupunkia ei löydy',
    required: 'Kenttä on pakollinen',
    createError: 'Jotain meni pieleen',
    successfullyCreate: 'Loistava! Tuotteesi on luotu onnistuneesti!',
    selectCatalog: 'Valitse luettelo',
    editCategoryBtn: 'Vaihda luokkaa',
    saveBtn: 'Tallentaa',
    editH1: 'muokkaa sivua',
    deleteImage: 'Poista kuva galleriasta',
  },
  errors: {
    error: 'Jotain meni pieleen',
  },
  chat: {
    messageCounter: '0 viestejä | 1 viesti | {count} viestejä',
    messageInputPlaceholder: 'Kirjoita viestisi',
  },
  filters: {
    title: 'Suodata tulokset',
    placesFilterTitle: 'Valitse paikka',
    categoriesFilterTitle: 'Valitut suodattimet',
    regionLabel: 'Kunta',
    cityLabel: 'Kaupunki',
    applyFiltersBtn: 'Käytä suodattimia',
    resetAllFiltersBtn: 'Nollaa kaikki suodattimet',
  },
  categories: {
    showCategoriesDropdownBtn: 'Kaikki kategoriat',
  },
  viewsPagesSettingsPassword: {
    enable2faLabel: 'Ota 2FA-todennus käyttöön',
    enable2faBtn: 'Ota Two-factor käyttöön',
    p1: 'Kaksivaiheinen todennus (2FA) on yleinen tapa lisätä tilillesi ylimääräinen suojauskerros. Jos 2FA on käytössä tililläsi, sisäänkirjautumisen aikana sinua pyydetään syöttämään luotu koodi (kertakoodi) todennuksen viimeistelemiseksi.',
    p2: '2FA:n käyttämiseen tarvitaan kertakoodigeneraattori, kuten Google Authenticator, Authy tai One Time Password.',
    p3: '1. Skannaa QR-koodi valitsemallasi kertakoodigeneraattorisovelluksella.',
    p4: '2. Kirjoita luotu koodi One Time Passcode -ruutuun ja napsauta Vahvista ja ota kaksikerroinen käyttöön.',
    label: 'Syötä 6-numeroinen koodi yhdestä aikakoodigeneraattorista',
    submitBtn: 'Vahvista ja ota käyttöön 2FA',
    requiredInput: 'Kenttä on pakollinen',
    confirmh1: '2FA on otettu käyttöön',
    confirmp1:
      '2 Factor Authentication on nyt määritetty tilillesi. Kun seuraavan kerran kirjaudut sisään, sähköpostiosoitteesi ja salasanasi antamisen jälkeen sinua pyydetään antamaan valitsemasi sovelluksen luoma kertakoodi. Avaa vain Kertakoodin luontisovellus, kirjoita luotu koodi ennen kuin ajastin loppuu ja paina Lähetä.',
    confirmp2: 'Tallenna palautuskoodit',
    confirmp3:
      'Käytä näitä kertaluonteisia palautuskoodeja päästäksesi tilillesi, kun voit vastaanottaa koodeja todennussovelluksestasi:',
    confirmBtn: 'Selvä!',
    errors: {
      invalid_code: 'Annettu koodi on virheellinen.',
      invalid_recovery_code: 'Annettu palautuskoodi on virheellinen.',
      throttle: 'Liian monta yritystä. Odota ennen kuin yrität uudelleen.',
    },
  },
  mfaConfirm: {
    h1: '2FA-todennus',
    btnText: 'Lähettää',
    recoveryText: 'Onko sinulla ongelmia? Käytä palautuskoodeja.',
    recoveryH1: 'Palautus 2FA',
    recoveryInfoText: 'Anna palautuskoodi, jonka sait, kun määrität kaksivaiheisen todennuksen',
    recoveryLabel: 'Anna palautuskoodi',
  },
  productSingle: {
    createdOn: 'Luotu:',
    sendMsgBtn: 'Lähetä viesti',
  },
  productStatus: {
    statusLabel: 'Tila',
    new: 'Uusi',
    moderate: 'Tarkistetaan moderaattorilta',
    active: 'Aktiivinen',
    sold: 'Myyty',
    reserved: 'Varattu',
    disabled: 'Liikuntarajoitteinen',
    canceled: 'Peruutettu',
  },
  settings: {
    account: 'Tili',
    profile: 'Profiili',
    security: 'Turvallisuus',
  },
  password: {
    passwordH2: 'Salasana',
    mfaH2: '2FA-todennus',
    mfaDisableText: 'Kaksivaiheinen todennus on käytössä. Poistaaksesi käytöstä - napsauta alla olevaa painiketta.',
    oldPasswordLabel: 'Vanha salasana',
    passwordLabel: 'Uusi salasana',
    passwordConfirmLabel: 'Vahvista salasana',
    mfaDisableTextBtn: 'Poista 2FA käytöstä',
    recoveryCodesLabel: 'Palautuskoodisi',
  },
  profile: {
    emailVerified: 'Vahvistettu: ',
    emailNotVerified: 'Ei vahvistettu!',
    nameLabel: 'Nimi',
    surnameLabel: 'Sukunimi',
    phoneNumber: 'Puhelinnumero',
    city: 'Kaupunki',
    street: 'Katu',
    birthdayDate: 'Syntymäpäivä',
    saveBtn: 'Tallentaa',
    h2: 'Profiili',
    cancelBtn: 'Peruuttaa',
  },
  socialShare: {
    shareTitle: 'Jakamalla tämän mainoksen autat sivustoamme parantamaan.',
  },
  verifyEmail: {
    titleConfirmed: 'Sähköpostiosoitteesi on jo vahvistettu',
    titleNotConfirmed: 'Vahvista sähköpostisi',
    goHomeBtn: 'Siirry pääsivulle',
    verifyBtn: 'Vahvista napsauttamalla',
    verifyBtnError: 'Oho! Lähetetäänkö vahvistus uudelleen?',
  },
  googleAnalytics: {
    text: 'Käytämme evästeitä parantaaksemme käyttökokemustasi. Hyväksyt evästeiden käytön napsauttamalla "Hyväksy kaikki evästeet" - painiketta. Voit myös kieltäytyä.',
    consentText: 'Hyväksy kaikki evästeet',
    refusalText: 'Hylätä',
  },
  sameProductsInTheCity: {
    h2: 'Samat tuotteet tässä kaupungissa',
  },
  sameProducts: {
    h2: 'Samat tuotteet tässä kategoriassa',
  },
  appFooter: {
    confidentialText1: 'sopi.fi on suomalainen ilmoitussivusto.',
    confidentialText2: 'sopi.fi säännöt.',
    confidentialText3: 'Tietosuojakäytäntö.',
    confidentialText4: 'Käyttämällä sivustoa sopi.fi hyväksyt tarjouksen.',
    aboutUsLink1: 'Käyttöehdot',
    aboutUsLink2: 'Tietosuojakäytäntö',
    aboutUsLink3: 'Yhteystiedot',
    cooperationHeader: 'Yhteistyö',
    cooperationLink1: 'Mainonta',
    cooperationLink2: 'Kumppanuus',
    cooperationLink3: 'Lahjoitukset',
    supportHeader: 'Tuki',
    supportLink1: 'Asiakastuki',
    supportLink2: 'Turvalliset kaupat',
    supportLink3: 'Ilmoita ongelmasta',
  },
};
