import { reactive } from 'vue';

export default {
  namespaced: true,
  state() {
    return {
      posts: [],
      adminPosts: [],
      pagination: {
        currentPage: 1,
        lastPage: 10,
      },
      postStatuses: [],
      languages: [],
      languagesById: reactive({}),
      languagesByLocale: reactive({}),
    };
  },
  getters: {
    adminPosts: (state) => state.adminPosts,
    pagination: (state) => state.pagination,
    postStatuses: (state) => state.postStatuses,
    languages: (state) => state.languages,
    languagesById: (state) => state.languagesById,
    languagesByLocale: (state) => state.languagesByLocale,
  },
  mutations: {
    setPosts(state, { posts }) {
      state.posts = posts;
    },

    setAdminPosts(state, { posts }) {
      state.adminPosts = posts;
    },

    setPaginationParams(state, { pagination }) {
      Object.assign(state.pagination, pagination);
    },

    setPostStatuses(state, { statuses }) {
      state.postStatuses = statuses;
    },

    setLanguages(state, { languages }) {
      state.languages = languages;
    },

    setLanguage: ({ languagesById, languagesByLocale }, { language }) => {
      languagesById[language.id] = language;
      languagesByLocale[language.code] = language;
    },
  },

  actions: {
    async getAdminPosts({ commit }, { status = null, page = null }) {
      await this.$axios
        .post('/admin/blog/list', {
          status,
          page,
        })
        .then((res) => {
          commit('setAdminPosts', { posts: res.data.data });
          commit('setPaginationParams', {
            pagination: {
              currentPage: res.data['current_page'],
              lastPage: res.data['last_page'],
            },
          });
        });
    },

    getPostById({ commit }, { id }) {
      return this.$axios.get(`/admin/blog/single/${id}`);
    },

    getPostBySlug({ commit }, { slug }) {
      return this.$axios.get(`/blog/${slug}`);
    },

    async getPossibleStatuses({ commit }) {
      await this.$axios.get('/admin/blog/statuses').then((res) => {
        commit('setPostStatuses', { statuses: res.data });
      });
    },

    async getPossibleLanguages({ commit }) {
      await this.$axios.get('/languages').then((res) => {
        commit('setLanguages', { languages: res.data });
        res.data.forEach((language) => {
          commit('setLanguage', { language });
        });
      });
    },

    setNewPost(_, { post }) {
      return this.$axios.post('/admin/blog/new-post', { ...post });
    },

    editPost(_, { post }) {
      return this.$axios.patch('/admin/blog/update-post', { ...post });
    },
  },
};
