export default {
  namespaced: true,
  state() {
    return {
      rooms: null,
    };
  },
  getters: {
    rooms: (state) => state.rooms,
  },
  mutations: {
    setRooms(state, { rooms }) {
      state.rooms = rooms;
    },
  },
  actions: {
    async getChatRooms({ commit }) {
      await this.$axios.get('/chats').then((res) => {
        commit('setRooms', { rooms: res.data });
      });
    },
  },
};
