const initState = () => ({
  wishProductUuid: [], // Array<String>
  userWishes: [], // Array<Object>
});

export default {
  namespaced: true,

  state: initState,

  getters: {
    wishProductUuid(state) {
      return state.wishProductUuid;
    },

    userWishes(state) {
      return state.userWishes;
    },
  },
  mutations: {
    SET_WISH_PRODUCT_UUID(state, { wishes }) {
      state.wishProductUuid = [...wishes.map((wish) => wish['product_id'])];
    },

    SET_USER_WISHES(state, { wishes }) {
      state.userWishes = wishes;
    },

    RESET: (state) => Object.assign(state, initState()),
  },
  actions: {
    async getUserWishes({ commit }) {
      await this.$axios.get('/user-wishes').then((r) => {
        commit('SET_WISH_PRODUCT_UUID', { wishes: r.data });
      });
    },

    async updateUserWishes({ commit }, { id }) {
      await this.$axios.post('/update-user-wishes', { product_id: id }).then((r) => {
        commit('SET_WISH_PRODUCT_UUID', { wishes: r.data });
      });
    },

    async getUserWishesList({ commit }) {
      await this.$axios.get('/user-wishes-list').then((r) => {
        commit('SET_USER_WISHES', { wishes: r.data });
      });
    },
  },
};
