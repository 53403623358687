import { PRODUCTS_VIEW } from '../../constants';

export default {
  namespaced: true,
  state() {
    return {
      leftDrawerOpen: false,
      productsView: PRODUCTS_VIEW.CARD,
    };
  },
  getters: {
    isLeftNavigationDrawerOpen: (state) => state.leftDrawerOpen,
    productsViewType: (state) => state.productsView,
  },
  mutations: {
    toggleLeftDrawer(state) {
      state.leftDrawerOpen = !state.leftDrawerOpen;
    },
    changeProductsView(state, { view = null }) {
      if (view) state.productsView = view;
      else {
        state.productsView = state.productsView === PRODUCTS_VIEW.LIST ? PRODUCTS_VIEW.CARD : PRODUCTS_VIEW.LIST;
      }
    },
  },
  actions: {
    toggleLeftDrawer(context) {
      context.commit('toggleLeftDrawer');
    },
    changeProductsView({ commit }, { view = null }) {
      commit('changeProductsView', { view });
    },
  },
};
