<template>
  <div class="text-center">
    <v-menu v-model="menu" :close-on-content-click="false" location="end">
      <template #activator="{ props }">
        <UserIcon v-bind="props" />
      </template>

      <v-card v-if="authenticated" min-width="300">
        <v-list>
          <v-list-item :prepend-avatar="user.avatar" :title="user.name"> </v-list-item>
          <v-list-item
            v-for="item in items"
            :key="item.key"
            :prepend-icon="item.icon"
            :title="item.text"
            :value="item.key"
            :to="item.to"
          ></v-list-item>
        </v-list>
      </v-card>
      <v-card v-else min-width="300">
        <v-list>
          <v-list-item
            v-for="item in items"
            :key="item.key"
            :prepend-icon="item.icon"
            :title="item.text"
            :value="item.key"
            :to="item.to"
          ></v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import UserIcon from './UserIcon.vue';
import { mapGetters } from 'vuex';
import { AUTH_MENU, USER_MENU } from '../../constants';

export default {
  name: 'UserPopoverEnterMenu',

  components: {
    UserIcon,
  },

  data: () => ({
    fav: true,
    menu: false,
    message: false,
    hints: true,
  }),

  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),

    items() {
      const { $i18n, authenticated } = this;
      return authenticated ? USER_MENU({ $i18n }) : AUTH_MENU({ $i18n });
    },
  },
};
</script>

<style scoped></style>
