<template>
  <v-tooltip :text="$i18n.messages[$i18n.locale].appSwitchButton.tooltipText">
    <template #activator="{ props }">
      <v-btn v-if="!state" icon v-bind="props" flat @click="$emit('input')">
        <v-icon color="grey">mdi-format-list-bulleted-square</v-icon>
      </v-btn>
      <v-btn v-else v-bind="props" flat icon @click="$emit('input')">
        <v-icon color="grey">mdi-view-column</v-icon>
      </v-btn>
    </template>
  </v-tooltip>
</template>

<script>
export default {
  name: 'AppSwitchButton',

  props: {
    state: { type: Boolean, default: true },
    firstStateIcon: {
      type: String,
      default: 'mdi-format-list-bulleted-square',
    },
    secondStateIcon: { type: String, default: 'mdi-view-column' },
  },
};
</script>

<style scoped></style>
