<template>
  <v-tooltip :text="user && user.name ? user.name : $i18n.messages[$i18n.locale].userIcon.tooltipText">
    <template #activator="{ props }">
      <v-btn v-if="authenticated" icon v-bind="props" @click="$emit('click', $event)">
        <v-avatar size="24">
          <v-img :src="user.avatar"></v-img>
        </v-avatar>
      </v-btn>
      <v-btn v-else v-bind="props" icon @click="$emit('click', $event)">
        <v-icon> mdi-account-circle</v-icon>
      </v-btn>
    </template>
  </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UserIcon',

  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
  },
};
</script>

<style scoped></style>
