<template>
  <div class="GoogleAnalytics">
    <p class="pb-3">{{ $i18n.messages[$i18n.locale].googleAnalytics.text }}</p>

    <div class="GoogleAnalytics__buttons">
      <v-btn
        color="primary"
        :text="$i18n.messages[$i18n.locale].googleAnalytics.consentText"
        @click="$emit('consent', true)"
      ></v-btn>
      <v-btn
        class="ml-2"
        color="grey"
        :text="$i18n.messages[$i18n.locale].googleAnalytics.refusalText"
        @click="$emit('consent', false)"
      ></v-btn>
    </div>
  </div>
</template>

<style lang="sass" scoped>
.GoogleAnalytics
  padding: 2rem

  p
    color: #fff

  &__buttons
    display: flex
    justify-content: space-between
</style>
