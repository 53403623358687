import Cookies from 'js-cookie';
import toast from '@store/modules/toast';
import router from '@/router';
import { USER_ROLES, SUPER_ADMIN, ADMIN, USER, MODERATOR, EDITOR, UNVERIFIED } from '../../constants';

export default {
  namespaced: true,
  state() {
    return {
      authenticated: false,
      user: null,
      roles: {
        [SUPER_ADMIN]: false,
        [ADMIN]: false,
        [MODERATOR]: false,
        [EDITOR]: false,
        [USER]: false,
        [UNVERIFIED]: false,
      },
      token: Cookies.get('token'),
      logins: {
        facebook: false,
        twitter: false,
        instagram: false,
        github: false,
        youtube: false,
        google: true,
        linkedin: false,
        twitch: false,
        apple: false,
        microsoft: false,
        tiktok: false,
        zoho: false,
        stackexchange: false,
        gitlab: false,
        reddit: false,
        snapchat: false,
        meetup: false,
        bitbucket: false,
        atlassian: false,
        // NEW_PROVIDER_PLUG :: Put New Provider HERE
      },
      currentUserToken: null,
      impersonatorToken: null,
      darkMode: false,
    };
  },
  getters: {
    user(state) {
      return state.user;
    },
    roles(state) {
      return state.roles;
    },
    verified(state) {
      if (state.user) return state.user.email_verified_at;
      return null;
    },
    id(state) {
      if (state.user) return state.user.id;
      return null;
    },
    authenticated(state) {
      return state.authenticated;
    },
    token(state) {
      return state.token;
    },
    logins(state) {
      return state.logins;
    },
    currentUserToken(state) {
      return state.currentUserToken;
    },
    impersonatorToken(state) {
      return state.impersonatorToken;
    },
    darkMode(state) {
      return state.darkMode;
    },
  },
  mutations: {
    SET_AUTHENTICATION(state, value = false) {
      state.authenticated = value;
    },
    SET_USER(state, payload = null) {
      const APP_GA_ENABLED = GA_ENABLED; // eslint-disable-line
      const APP_GA_TAG = GA_TAG; // eslint-disable-line
      state.user = payload;
      if (state && state.user && state.user.id && APP_GA_ENABLED == 1) {
        // gtag('config', APP_GA_TAG, {
        //   user_id: state.user.id,
        // });
        gtag('set', {
          user_id: state.user.id,
        });
      }
      if (payload && payload.roles && payload.roles.length > 0) {
        payload.roles.forEach((role) => {
          if (role.slug === USER_ROLES[SUPER_ADMIN]) state.roles[SUPER_ADMIN] = true;
          if (role.slug === USER_ROLES[ADMIN]) state.roles[ADMIN] = true;
          if (role.slug === USER_ROLES[MODERATOR]) state.roles[MODERATOR] = true;
          if (role.slug === USER_ROLES[EDITOR]) state.roles[EDITOR] = true;
          if (role.slug === USER_ROLES[USER]) state.roles[USER] = true;
          if (role.slug === USER_ROLES[UNVERIFIED]) state.roles[UNVERIFIED] = true;
        });
      } else {
        state.roles = {
          [SUPER_ADMIN]: false,
          [ADMIN]: false,
          [MODERATOR]: false,
          [EDITOR]: false,
          [USER]: false,
          [UNVERIFIED]: false,
        };
      }
    },
    SET_THEME(state, payload = null) {
      if (payload) {
        document.documentElement.classList.add('dark');
        state.darkMode = true;
      } else {
        document.documentElement.classList.remove('dark');
        state.darkMode = false;
      }
    },
    SET_TOKEN(state, { token, remember }) {
      state.token = token;
      Cookies.set('token', token, { expires: remember ? 365 : null });
    },
    SET_WORKING_TOKENS(state, payload) {
      state.currentUserToken = payload.currentUserToken;
      state.impersonatorToken = payload.impersonatorToken;
    },
    SET_LOGINS(state, payload = null) {
      if (payload) {
        state.logins = payload;
      }
    },
    LOGOUT(state) {
      state.authenticated = false;
      state.user = null;
      state.roles = {
        [SUPER_ADMIN]: false,
        [ADMIN]: false,
        [MODERATOR]: false,
        [EDITOR]: false,
        [USER]: false,
        [UNVERIFIED]: false,
      };
      document.documentElement.classList.remove('dark');
      state.token = null;
      state.currentUserToken = null;
      state.impersonatorToken = null;
      Cookies.remove('token');
      window.sessionStorage.clear();
    },
  },
  actions: {
    async login({ dispatch }, payload) {
      try {
        await this.$axios.get('/sanctum/csrf-cookie');
        return await this.$axios.post('/login', payload).then(async (res) => {
          await dispatch('getUser');
          return res;
        });
      } catch (e) {
        throw e;
      }
    },

    async register({ dispatch, commit }, payload) {
      await this.$axios.get('/sanctum/csrf-cookie');
      const res = await this.$axios.post('/register', payload);
      commit('LOGOUT');

      return res;
    },

    async logout({ commit }) {
      await this.$axios
        .post('/logout')
        .then((res) => {
          commit('LOGOUT');
          commit('wishes/RESET', 'wishes', { root: true });
        })
        .catch((err) => {});
      router.push({ name: 'home' });
    },

    async getUser({ commit, dispatch }) {
      await this.$axios
        .get('/user')
        .then((res) => {
          if (res && res.data && res.data.id) {
            commit('SET_USER', res.data);
            commit('SET_THEME', res.data.theme_dark);
            commit('SET_AUTHENTICATION', true);
            dispatch('wishes/getUserWishes', 'wishes', { root: true });
          } else {
            commit('LOGOUT');
          }
        })
        .catch((err) => {
          commit('LOGOUT');
          throw err.response;
        });
    },

    async getUserByToken({ commit, dispatch }, payload) {
      await this.$axios
        .post('/user-by-token', { token: payload.token })
        .then((res) => {
          if (res && res.data && res.data.id) {
            commit('SET_USER', res.data);
            commit('SET_THEME', res.data.theme_dark);
            commit('SET_AUTHENTICATION', true);
            dispatch('wishes/getUserWishes', 'wishes', { root: true });
          } else {
            commit('LOGOUT');
          }
        })
        .catch((err) => {
          commit('LOGOUT');
          throw err.response;
        });
    },

    async profile({ commit }, payload) {
      const res = await this.$axios.patch('/profile', payload);
      if (res.status == 200 && res && res.data && res.data.user && res.data.user.id) {
        commit('SET_USER', res.data.user);
        commit('SET_THEME', res.data.user.theme_dark);
        commit('SET_AUTHENTICATION', true);
        return res;
      }
      // commit('LOGOUT');
      throw res.response;
    },

    async theme({ commit }, payload) {
      const res = await this.$axios.patch('/theme', payload);
      if (res.status == 200 && res && res.data && res.data.user && res.data.user.id) {
        commit('SET_USER', res.data.user);
        commit('SET_THEME', res.data.user.theme_dark);
        commit('SET_AUTHENTICATION', true);
        return res;
      }
      // commit('LOGOUT');
      throw res.response;
    },

    async password({ dispatch }, payload) {
      return await this.$axios.patch('/password', payload);
    },

    async verifyResend({ dispatch }, payload) {
      const res = await this.$axios.post('/verify-resend', payload);
      if (res.status != 200) throw res;
      return res;
    },

    async verifyEmail({ dispatch }, payload) {
      const res = await this.$axios.post(`/verify-email/${payload.id}/${payload.hash}`);
      if (res.status != 200) throw res;
      dispatch('getUser');
      return res;
    },

    async forgotPassword({ dispatch }, payload) {
      try {
        const response = await this.$axios.post('/forgot-password', payload);
        if (response && response.status && response.status == 200 && response.data && response.data.message) {
          return response.data.message;
        }
        throw response;
      } catch (error) {
        throw error;
      }
    },

    async resetPassword({ dispatch }, payload) {
      try {
        const response = await this.$axios.post('/reset-password', payload);
        if (response && response.status && response.status == 200 && response.data && response.data.message) {
          return response.data.message;
        }
        throw response;
      } catch (error) {
        throw error;
      }
    },

    async fetchOauthUrl(ctx, { provider }) {
      try {
        const response = await this.$axios.post(`/oauth/${provider}`);
        if (response && response.data && response.data.url) {
          return response.data.url;
        }
        throw response;
      } catch (error) {
        throw error;
      }
    },

    async saveToken({ commit, dispatch }, payload) {
      commit('SET_TOKEN', payload);
    },

    async getLogins({ commit }) {
      await this.$axios
        .get('/logins')
        .then((res) => {
          if (res && res.data && res.data.logins) {
            commit('SET_LOGINS', res.data.logins);
          } else {
            throw res;
          }
        })
        .catch((err) => {
          throw err.response;
        });
    },

    async revokeProvider({ commit, dispatch }, payload) {
      return await this.$axios
        .post(`/oauth-revoke/${payload.id}`)
        .then((res) => {
          if (
            res.status &&
            res.status == 200 &&
            res.data &&
            res.data.status &&
            res.data.status == 'success' &&
            res.data.user &&
            res.data.user.id
          ) {
            commit('SET_USER', res.data.user);
            return res;
          }
          throw res;
        })
        .catch((err) => {
          throw err.response;
        });
    },

    async deleteAccount({ commit, dispatch }, payload) {
      return await this.$axios
        .delete(`/account/${payload.id}/delete`)
        .then((res) => {
          if (
            res.status &&
            res.status == 200 &&
            res.data &&
            res.data.status &&
            res.data.status == 'success' &&
            !res.data.user
          ) {
            commit('LOGOUT');
            router.push({ name: 'home' });

            return res;
          }
          throw res;
        })
        .catch((err) => {
          throw err.response;
        });
    },

    async impersonateUser({ commit, dispatch }, payload) {
      await this.$axios
        .get(`/impersonate/take/${payload.user.id}`)
        .then((res) => {
          if (res && res.data && res.data.data && res.data.data.token) {
            commit('LOGOUT');
            commit('SET_WORKING_TOKENS', {
              currentUserToken: res.data.data.token,
              impersonatorToken: res.data.data.impersonatorToken,
            });
            dispatch('getUserByToken', { token: res.data.data.token });
            router.push({ name: 'home' });
            dispatch(
              'toast/popToast',
              {
                message: `Stealh mode activated. You are now acting as ${payload.user.name}`,
                timer: 5000,
                icon: 'success',
              },
              { root: true },
            );
          } else {
            dispatch(
              'toast/popToast',
              {
                message: 'An error occurred, you are still yourself.',
                timer: 5000,
                icon: 'error',
              },
              { root: true },
            );
          }
        })
        .catch((err) => {
          dispatch(
            'toast/popToast',
            {
              message: 'An error occurred, you are still yourself.',
              timer: 5000,
              icon: 'error',
            },
            { root: true },
          );
          throw err.response;
        });
    },

    async leaveImpersonatingUser({ commit, dispatch }, payload) {
      await this.$axios
        .get(`/impersonate/leave`)
        .then((res) => {
          if (res && res.data && res.data.data && res.data.data.token) {
            commit('LOGOUT');
            dispatch('getUserByToken', { token: res.data.data.token });
            router.push({ name: 'home' });
            dispatch(
              'toast/popToast',
              {
                message: `You wake up and realize it was all dream!`,
                timer: 5000,
                icon: 'success',
              },
              { root: true },
            );
          } else {
            dispatch(
              'toast/popToast',
              {
                message: 'An error occurred, you are still are not yourself!',
                timer: 5000,
                icon: 'error',
              },
              { root: true },
            );
          }
        })
        .catch((err) => {
          dispatch(
            'toast/popToast',
            {
              message: 'An error occurred, you are still yourself.',
              timer: 5000,
              icon: 'error',
            },
            { root: true },
          );
          throw err.response;
        });
    },

    async getUserInfo(_, { id }) {
      return await this.$axios.get(`/user-info/${id}`).catch((err) => {
        throw err.response;
      });
    },

    async enable2fa(_) {
      const res = await this.$axios.get(`/enable2fa/`);
      return res;
    },

    async show2fa(_) {
      const res = await this.$axios.get(`/show2fa/`);
      return res;
    },

    async confirm2fa(_, { code }) {
      const res = await this.$axios.post(`/confirm2fa/`, { code });
      return res;
    },

    async check2faCode({ dispatch }, { code }) {
      const res = await this.$axios.post(`/check2faCode/`, { code });
      return res;
    },

    async disable2fa({ dispatch }) {
      const res = await this.$axios.post(`/disable2fa/`);
      dispatch('getUser');
      return res;
    },

    async recovery2faWithCode({ dispatch }, { code }) {
      const res = await this.$axios.post(`/recovery2faWithCode/`, {
        recovery_code: code,
      });
      return res;
    },
  },
};
