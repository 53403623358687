<template>
  <v-footer class="AppFooter pa-5" padless>
    <v-container>
      <v-row>
        <!-- Logo and text -->
        <v-col cols="12" md="4" class="d-flex flex-column align-center mb-4">
          <v-row class="mt-4">
            <v-col cols="12">
              <v-col cols="12">
                <LogoIcon class="AppFooter__logo" @click="changeRoute" />
              </v-col>
              <v-text class="text-center"> {{ $i18n.messages[$i18n.locale].appFooter.confidentialText1 }} </v-text
              ><br />
              <v-text class="text-center"> {{ $i18n.messages[$i18n.locale].appFooter.confidentialText2 }} </v-text
              ><br />
              <v-text class="text-center"> {{ $i18n.messages[$i18n.locale].appFooter.confidentialText3 }} </v-text
              ><br />
              <v-text class="text-center"> {{ $i18n.messages[$i18n.locale].appFooter.confidentialText4 }} </v-text
              ><br />
              <v-text class="text-center pt-2">
                © 2023 - {{ new Date().getFullYear() }} — <strong>sopi.fi</strong>
              </v-text>
            </v-col>
          </v-row>
        </v-col>

        <!-- Links section -->
        <v-col cols="12" md="8">
          <v-row>
            <v-col cols="12" md="4">
              <h4 class="text-uppercase ml-4">Sopi.fi</h4>
              <v-list density="compact" dense>
                <v-list-item link to="/info/terms-of-use">
                  <v-list-item-title>{{ $i18n.messages[$i18n.locale].appFooter.aboutUsLink1 }}</v-list-item-title>
                </v-list-item>
                <v-list-item link to="/info/privacy-policy">
                  <v-list-item-title>{{ $i18n.messages[$i18n.locale].appFooter.aboutUsLink2 }}</v-list-item-title>
                </v-list-item>
                <v-list-item link to="/info/contacts">
                  <v-list-item-title>{{ $i18n.messages[$i18n.locale].appFooter.aboutUsLink3 }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>

            <v-col cols="12" md="4">
              <h4 class="text-uppercase ml-4">{{ $i18n.messages[$i18n.locale].appFooter.cooperationHeader }}</h4>
              <v-list density="compact" dense>
                <v-list-item link to="/info/advertisement">
                  <v-list-item-title>{{ $i18n.messages[$i18n.locale].appFooter.cooperationLink1 }}</v-list-item-title>
                </v-list-item>
                <v-list-item link to="/info/partnership">
                  <v-list-item-title>{{ $i18n.messages[$i18n.locale].appFooter.cooperationLink2 }}</v-list-item-title>
                </v-list-item>
                <v-list-item link to="/info/donations">
                  <v-list-item-title>{{ $i18n.messages[$i18n.locale].appFooter.cooperationLink3 }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>

            <v-col cols="12" md="4">
              <h4 class="text-uppercase ml-4">{{ $i18n.messages[$i18n.locale].appFooter.supportHeader }}</h4>
              <v-list dense>
                <!-- <v-list-item link>
                  <v-list-item-title>{{ $i18n.messages[$i18n.locale].appFooter.supportLink1 }}</v-list-item-title>
                </v-list-item> -->
                <v-list-item link to="/info/safe-shops">
                  <v-list-item-title>{{ $i18n.messages[$i18n.locale].appFooter.supportLink2 }}</v-list-item-title>
                </v-list-item>
                <!-- <v-list-item link>
                  <v-list-item-title>{{ $i18n.messages[$i18n.locale].appFooter.supportLink3 }}</v-list-item-title>
                </v-list-item> -->
              </v-list>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider class="my-4"></v-divider>
    </v-container>
  </v-footer>
</template>

<script>
import LogoIcon from '/resources/img/logo.svg?component';

export default {
  name: 'AppFooter',

  components: {
    LogoIcon,
  },

  methods: {
    changeRoute() {
      this.$router
        .push({
          name: 'home',
          query: { filters: {} },
        })
        .catch((e) => e);
    },
  },
};
</script>

<style scoped lang="sass">
.AppFooter
  &__logo
    width: 130px
    max-height: 65px
    cursor: pointer
    margin-bottom: 20px

.v-list-item-title
  font-size: 14px

.text-center
  font-size: 14px

.v-footer
  border-top: 1px solid #e0e0e0
  margin-top: 30px

.v-divider
  background-color: #e0e0e0
</style>
