<template>
  <div className="UserLocale">
    <v-select v-model="$i18n.locale" hide-details density="compact" :items="langs" />
  </div>
</template>

<script>
export default {
  name: 'UserLocale',

  props: {
    langs: { type: Array, required: true },
  },
};
</script>

<style scoped lang="sass">
.UserLocale
  max-width: 80px
  min-width: 80px
</style>
