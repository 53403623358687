<template>
  <v-toolbar density="compact">
    <v-app-bar-nav-icon @click="toggleLeftDrawer"></v-app-bar-nav-icon>

    <div class="AppNav__logo" @click="changeRoute">
      <LogoIcon style="width: 80px" />
    </div>

    <v-spacer></v-spacer>

    <UserLocale :langs="langs" />

    <router-link :to="{ name: 'messenger' }">
      <v-btn v-if="user && user.id" icon>
        <v-tooltip :text="$i18n.messages[$i18n.locale].appNav.messengerTooltip" activator="parent" />
        <v-badge v-if="false" :content="wishes.length" color="rgb(244 67 54 / 87%)">
          <v-icon color="grey">mdi-message-fast-outline</v-icon>
        </v-badge>
        <v-icon v-else color="grey">mdi-message-fast-outline</v-icon>
      </v-btn>
    </router-link>

    <router-link :to="{ name: 'wishes' }">
      <v-btn v-if="user && user.id" icon>
        <v-tooltip :text="$i18n.messages[$i18n.locale].appNav.wishTooltip" activator="parent" />
        <v-badge v-if="wishes.length" :content="wishes.length" color="red">
          <v-icon color="red">mdi-heart-outline</v-icon>
        </v-badge>
        <v-icon v-else color="red">mdi-heart-outline</v-icon>
      </v-btn>
    </router-link>

    <UserPopoverEnterMenu />
  </v-toolbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { parseDisplayDate } from '@services/common';
import { Popover, PopoverButton, PopoverGroup, PopoverPanel, Switch } from '@headlessui/vue';
import {
  HomeIcon,
  BuildingLibraryIcon,
  InformationCircleIcon,
  DocumentTextIcon,
  XMarkIcon,
  CogIcon,
  ArrowLeftOnRectangleIcon,
  ChevronDownIcon,
  UserCircleIcon,
} from '@heroicons/vue/24/outline';

import LogoIcon from '/resources/img/logo.svg?component';

import UserLocale from '@components/UserLocale.vue';
import UserIcon from './users/UserIcon.vue';
import UserPopoverEnterMenu from './users/UserPopoverEnterMenu.vue';

export default {
  name: 'AppNav',
  components: {
    HomeIcon,
    BuildingLibraryIcon,
    InformationCircleIcon,
    DocumentTextIcon,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    XMarkIcon,
    CogIcon,
    ArrowLeftOnRectangleIcon,
    ChevronDownIcon,
    UserCircleIcon,
    Switch, // eslint-disable-line
    UserLocale,
    UserIcon,
    UserPopoverEnterMenu,
    LogoIcon,
  },
  props: {},
  data() {
    return {
      appName: 'Workflow',
      // logo: "https://tailwindui.com/img/logos/workflow-mark.svg?color=indigo&shade=600",
      drop: false,
      errors: null,
      success: '',
      loading: false,

      langs: ['fi', 'en', 'ru'],
    };
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      roles: 'auth/roles',
      currentUserToken: 'auth/currentUserToken',
      impersonatorToken: 'auth/impersonatorToken',
      wishes: 'wishes/wishProductUuid',
    }),
    isImpersonating() {
      if (this.currentUserToken && this.impersonatorToken) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
      updateTheme: 'auth/theme',
      popToast: 'toast/popToast',
      leaveImpersonatingUser: 'auth/leaveImpersonatingUser',
      toggleLeftDrawer: 'ui/toggleLeftDrawer',
    }),
    parseDisplayDate,
    closeDrop() {
      this.drop = false;
    },
    openDrop() {
      this.drop = true;
    },
    changeRoute() {
      this.$router
        .push({
          name: 'home',
          query: { filters: {} },
        })
        .catch((e) => e);
    },
    async toggleTheme() {
      this.loading = true;
      this.errors = null;
      this.success = '';
      try {
        await this.updateTheme({ theme_dark: !this.user.theme_dark }).then((response) => {
          if (response && response.data && response.data.user && response.data.user.id) {
            this.popToast({
              message: 'Theme Saved',
              timer: 2000,
              icon: 'success',
            });
          }
        });
      } catch (e) {
        this.errors = e.data;
        this.popToast({
          message: 'Error Updating Theme',
          timer: 5000,
          icon: 'error',
        });
      }
      this.loading = false;
    },
    async leaveImpersonating() {
      try {
        await this.leaveImpersonatingUser().then((response) => {
          //
        });
      } catch (e) {
        this.popToast({
          message: 'Unable To Return To Yourself',
          timer: 5000,
          icon: 'error',
        });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.AppNav
  &__logo
    cursor: pointer
</style>
