<template>
  <v-layout class-name="App__main-layout">
    <v-app-bar color="grey" height="48" flat>
      <AppNav />
    </v-app-bar>

    <LeftNavigationDrawer class="LeftNavigationDrawer" />

    <v-main>
      <div className="App__content w-full">
        <router-view v-slot="{ Component }">
          <component :is="Component" />
        </router-view>
      </div>
      <!--      <v-container-->
      <!--        class="App__content d-flex align-center text-h5 justify-center"-->
      <!--      >-->
      <!--        Content {{ $i18n.messages[$i18n.locale].message.value }}-->
      <!--      </v-container>-->

      <v-bottom-sheet
        style="margin-bottom: 20px"
        inset
        :scrim="false"
        scroll-strategy="reposition"
        :persistent="true"
        :model-value="isShowCookieConsent"
      >
        <v-card style="background-color: rgb(76 175 80 / 86%)" height="100%">
          <GoogleAnalytics @consent="giveConsent($event)" />
        </v-card>
      </v-bottom-sheet>

      <AppFooter />

      <!-- Show dynamic modal component -->
      <component
        :is="currentModal(modal)"
        v-for="modal in dialogs"
        :id="modal.id"
        :key="modal.id"
        :is-open="modal.isOpen"
        v-bind="modal.props"
      />

      <AppToast />
    </v-main>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PagePropertiesMixin from '../mixins/pageMetaMixins';
import { defineAsyncComponent } from 'vue';
import { keepInStorage } from '../helpers';

import AppNav from '@components/AppNav.vue';
import AppToast from '../components/common/AppToast.vue';
import AppFooter from '@components/AppFooter.vue';
import VerifyNotice from '@components/VerifyNotice.vue';
import OctoCat from '@components/OctoCat.vue';
import LeftNavigationDrawer from '@components/LeftNavigationDrawer.vue';
import GoogleAnalytics from '../components/GoogleAnalytics.vue';

export default {
  components: {
    AppNav,
    AppToast,
    VerifyNotice,
    AppFooter,
    OctoCat,
    LeftNavigationDrawer,
    GoogleAnalytics,

    ImageCarouselModal: defineAsyncComponent(() => import('../components/modals/ImageCarouselModal.vue')),
    MfaEnableModal: defineAsyncComponent(() => import('../components/modals/MfaEnableModal.vue')),
    GoogleMapModal: defineAsyncComponent(() => import('../components/modals/GoogleMapModal.vue')),
  },

  mixins: [PagePropertiesMixin],

  data() {
    return {
      drawer: false,
      icons: ['mdi-facebook', 'mdi-twitter', 'mdi-linkedin', 'mdi-instagram'],
      locale: keepInStorage(this, '$i18n.locale', 'fi', {
        prefix: 'UserLocale',
        silent: true,
      }),
      isShowCookieConsent: keepInStorage(this, 'isShowCookieConsent', true, {
        prefix: 'GoogleAnalyticsConsent',
        silent: true,
      }),
    };
  },

  mounted() {
    const { locale } = this;
    const availableLocales = this.$i18n.availableLocales;
    const usersLanguage = navigator?.language || 'fi';

    if (locale) {
      this.$i18n.locale = locale;
    } else if (availableLocales.includes(usersLanguage)) {
      this.$i18n.locale = usersLanguage;
    } else {
      this.$i18n.locale = 'fi';
    }

    const consentGiven = localStorage.getItem('consentGiven');
    if (consentGiven !== null) {
      this.isShowCookieConsent = false;
    }
  },

  computed: {
    ...mapGetters({
      darkMode: 'auth/darkMode',
    }),

    currentRouteName() {
      return this.$route.name;
    },

    isAdminPage() {
      if (
        this.currentRouteName === 'admin' ||
        this.currentRouteName === 'roles' ||
        this.currentRouteName === 'permissions' ||
        this.currentRouteName === 'settings' ||
        this.currentRouteName === 'users' ||
        this.currentRouteName === 'app-settings' ||
        this.currentRouteName === 'phpinfo'
      ) {
        return true;
      }
      return false;
    },

    showOctoCat() {
      if (this.currentRouteName === 'home' || this.currentRouteName === 'about' || this.currentRouteName === 'terms') {
        return true;
      }
      return false;
    },

    dialogs() {
      return this.$store.state.modals.dialogs;
    },
  },
  methods: {
    ...mapActions({
      popToast: 'toast/popToast',
    }),

    currentModal(currentModal) {
      if (currentModal?.componentName) return currentModal.componentName;
      return null;
    },

    giveConsent(consent) {
      if (consent) {
        this.$gtag.query('consent', 'update', {
          ad_storage: 'granted',
          ad_personalization: 'granted',
          ad_user_data: 'granted',
          analytics_storage: 'granted',
          personalization_storage: 'granted',
          functionality_storage: 'granted',
          security_storage: 'granted',
        });
      } else {
        this.$gtag.query('consent', 'update', {
          ad_storage: 'denied',
          ad_personalization: 'denied',
          ad_user_data: 'denied',
          analytics_storage: 'denied',
          personalization_storage: 'denied',
          functionality_storage: 'denied',
          security_storage: 'denied',
        });
      }
      this.isShowCookieConsent = false;
    },
  },
};
</script>

<style scoped lang="sass">
.App
  &__content
    min-height: 100vh
</style>

<style lang="sass">
/* total width */
::-webkit-scrollbar
  background-color: #fff
  width: 12px

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track
  background-color: #fff

/* scrollbar itself */
::-webkit-scrollbar-thumb
  background-color: #babac0
  border-radius: 16px
  border: 4px solid #fff

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button
  display: none
</style>
