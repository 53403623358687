const pageMetGenerator = (meta) => {
  if (Array.isArray(meta) && meta.length) {
    document.head.querySelectorAll('[data-vue-router-controlled]').forEach((element, index, elements) => {
      document.head?.removeChild(element);
    });

    meta
      .map((tagDef) => {
        const tag = document.createElement('meta');
        Object.keys(tagDef).forEach((key) => {
          tag.setAttribute(key, tagDef[key]);
        });

        tag.setAttribute('data-vue-router-controlled', '');

        if (document.querySelector(`[name=${tagDef.name}]`)) {
          document.head.removeChild(document.querySelector(`[name=${tagDef.name}]`));
        }

        return tag;
      })
      .forEach((tag) => document.head.appendChild(tag));
  }
};

export default {
  data: () => {
    return {
      pageMetaTitle: '',
      pageMeta: [],
    };
  },
  watch: {
    $route(route) {
      if (route?.meta?.title) document.title = route?.meta?.title + ' | ' + import.meta.env.VITE_APP_NAME;
      if (route?.meta?.metaInfo?.length) pageMetGenerator(route?.meta?.metaInfo);
    },
    pageMetaTitle(title) {
      if (title) document.title = title + ' | ' + import.meta.env.VITE_APP_NAME;
    },
    pageMeta(meta) {
      pageMetGenerator(meta);
    },
  },
};
