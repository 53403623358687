let idSource = Number.MIN_SAFE_INTEGER;

const state = {
  dialogs: [], // Array<DialogState>
};

const getters = {};

const mutations = {
  PUSH_STATE: (state, { dialogState }) => state.dialogs.push(dialogState),

  REMOVE_STATE: (state, { id }) => {
    state.dialogs = state.dialogs.filter((d) => d.id !== id);
  },

  CLOSE_DIALOG: (_, { id }) => {
    state.dialogs.find((d) => d.id === id).isOpen = false;
  },
};

const actions = {
  OPEN_DIALOG: ({ commit }, { componentName = null, props = {}, listeners = {} }) => {
    const dialogState = {
      id: ++idSource,
      isOpen: true,
      props,
      listeners,
      componentName,
    };

    commit('PUSH_STATE', { dialogState });
    return dialogState;
  },

  CLOSE_MODAL: ({ commit }, { id }) => {
    commit('CLOSE_DIALOG', { id });
    setTimeout(() => commit('REMOVE_STATE', { id }), 1000);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
