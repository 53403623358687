import * as R from 'ramda';
import { watch, onBeforeUnmount } from 'vue';

export const isObjEmpty = (obj) => obj === null || Object.keys(obj).length === 0;
export const isItemEmpty = (item) =>
  item === undefined ||
  item === '' ||
  (Array.isArray(item) && item.every(isItemEmpty)) ||
  (typeof item === 'object' && isObjEmpty(item));

// To preserve some data in local/session storage
// Usage:
// export default {
//   data() {
//     return {
//       // in this example we want to preserve column order in localStorage
//       myColumnOrder: keepInStorage(
//         this, // vm instance to watch on
//         'columnOrder', // can be just a string 'columnOrder' in this simple case or an array of strings
//         ['id', 'name', 'created'], // initial value
//       )
//     }
//   },
// }
export const keepInStorage = (
  vm,
  prop,
  initialValue = null,
  {
    storage = localStorage,
    silent = false, // doesn't fall on unserializable garbage in local storage
    validate = null, // e.g.: `validate: v => R.is(Array, v) && v.every(item => ['id', 'name', 'created'].includes(item))
    deep = null, // true | false, default: true for arrays, objects and functions as an initial value
    prefix = null, // recommended, prefix (str) for prop to avoid collisions
    disable = false, // disable both dumping and loading on any condition, NB! not reactive
  } = {},
) => {
  const storageKey = (prefix ? prefix + ':' : '') + (R.is(String, prop) ? prop : JSON.stringify(prop));
  deep = deep ?? R.anyPass([R.is(Array), R.is(Object), R.is(Function)])(initialValue);
  vm.$nextTick(() => {
    vm.$watch(
      R.cond([
        [R.is(Array), R.join('.')],
        [R.is(String), R.identity],
        [R.is(Function), R.identity],
        [
          R.T,
          () => {
            throw new Error('Must be string/array/function');
          },
        ],
      ])(prop),
      (value) => {
        storage.setItem(storageKey, JSON.stringify(value));
      },
      { deep },
    );
  });

  const resolveInitialValue = () => (R.is(Function, initialValue) ? initialValue() : initialValue);

  if (disable) return resolveInitialValue();
  const rawValue = storage.getItem(storageKey);
  if (rawValue == null) return resolveInitialValue();

  let deserialized;
  try {
    deserialized = JSON.parse(rawValue);
  } catch (e) {
    if (silent) {
      console.warn(`Could not deserialize storage value for ${storageKey}`);
      return resolveInitialValue();
    } else {
      throw e;
    }
  }

  const validationRes = validate == null || validate(deserialized);
  if (validationRes !== true) {
    const errMsg = validationRes || `validation of ${storageKey} has failed`;
    if (silent) {
      console.warn(errMsg);
      return resolveInitialValue();
    } else {
      throw new Error(errMsg);
    }
  }

  return deserialized;
};

export const wait = (timeout = 0) => new Promise((resolve) => setTimeout(resolve, timeout));
